.changelog {
    max-width: 1024px;
    width: 100%;
    height: 900px;
    padding: 30px 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.changelog-item {
    padding-bottom: 25px;
}

.item-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 24px;
    border-bottom: 2px solid aliceblue;
}

.item-content {
    padding-top: 5px;
}
