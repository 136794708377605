.nav-bar {
    max-width: 1024px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    color: aliceblue;
    background-color: #3c4146;
    border-bottom-color: red;
}

.logo {
    height: 50px;
    width: 50px;
}

.app-name {
    display: flex;
    cursor: pointer;
    align-items: flex-end;
}

.app-name-item {
    padding-bottom: 5px;
}

.nav-buttons {
    display: flex;
    justify-content: space-between;
}

.nav-button {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: aliceblue;
    cursor: pointer;
}

.nav-button.active {
    font-weight: bold;
    border-bottom: 2px solid aliceblue;
}
