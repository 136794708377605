.footer {
    max-width: 1024px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    color: aliceblue;
    background-color: #3c4146;
    border-bottom-color: red;
}

.nav-buttons {
    display: flex;
    justify-content: space-between;
}

.nav-item {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: aliceblue;
}
