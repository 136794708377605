.global-types {
    max-width: 1024px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.global-types-item {
    padding-top: 25px;
}

.reference {
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
}

.table {
    table-layout: auto;
}

h2 {
    padding-top: 20px;
}

ul {
    margin-top: 4px;
}

img {
    width: 980px;
}
