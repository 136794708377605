.documentation {
    max-width: 1024px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.documentation-item {
    padding-top: 25px;
}

.list-intro {
    margin-bottom: 0;
}

.code-wrapper {
    background-color: #eff0f1;
    border-radius: 10px;
    padding: 20px;
}

.important-wrapper {
    background-color: lightyellow;
    border-radius: 10px;
    padding: 20px;
    border: 2px dashed yellow;
}

.prominent {
    background-color: yellow;
    padding-left: 5px;
    padding-right: 5px;
}

.pre-code {
    margin-bottom: 0;
}

.reference {
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
}

.table {
    table-layout: fixed;
}

h2 {
    padding-top: 20px;
}

ul {
    margin-top: 4px;
}

img {
    width: 980px;
}

.small-image {
    width: 600px;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.card-header {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}
