.impressum {
    max-width: 1024px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

h1 {
    padding-top: 25px;
    padding-bottom: 15px;
}
