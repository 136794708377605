.documentation-tabs {
    max-width: 1024px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    display: block;
}

.nav-item {
    color:  grey;
}
