.documentation {
    max-width: 1024px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    min-height: 751px;
}

.documentation-item {
    padding-top: 25px;
}
