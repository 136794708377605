.loader {
    max-width: 1024px;
    width: 100%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: 1px solid #ced4da;
    border-radius: .3rem;
}
