.nav-bar {
  max-width: 1024px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  color: aliceblue;
  background-color: #3c4146;
  border-bottom-color: red;
}

.app-name {
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
}

.nav-item {
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  color: aliceblue;
  cursor: pointer;
}

.nav-item.active {
  font-weight: bold;
  border-bottom: 2px solid aliceblue;
}





.App {
}

.App-logo {
  height: 40vmin;
}

.app-header {
  background-color: #3c4146;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.app-footer {
  background-color: #3c4146;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #e5e5e5;
}

.App-link {
  color: #09d3ac;
}
